<template>
    <div id="app">
        <LandingPage/>
    </div>
</template>

<script>
    import LandingPage from './components/LendingPage.vue'
    import './style.css';
    import './media.css';
    import './reset.css';
    import OpenSansReg from './assets/fonts/Gilroy/gilroy-regular.ttf';
    import OpenSansLight from './assets/fonts/TitilliumWeb/TitilliumWeb-Light.ttf';


    console.log(OpenSansReg, OpenSansLight);
    export default {
        name: 'App',
        components: {
            LandingPage
        },

    }
</script>

<style>

    /*@import url('style.css');*/
    /*@import url('media.css');*/
    /*@import url('reset.css');*/

    #app {
        font-family: Titillium Web;
    }

    /*#app {*/

    /*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*  -webkit-font-smoothing: antialiased;*/
    /*  -moz-osx-font-smoothing: grayscale;*/
    /*  text-align: center;*/
    /*  color: #2c3e50;*/
    /*  margin-top: 60px;*/
    /*}*/
</style>
